
import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  ref,
  computed,
  toRefs,
} from 'vue';
import { createInvestment } from '@/modules/requests';
import { AxiosResponse, AxiosError } from 'axios';
export default defineComponent({
  setup: () => {
    const modal = ref<any>(null);
    const successModal = ref<any>(null);
    const errorModal = ref<any>(null);

    const data: any = reactive({
      investment: {
        name: '',
        amount: '',
        tenure_id: null,
      },
      tab: 'create',
      investmentResource: null,
      error: null,
      loading: false,
    });

    const components: any = {
      create: defineAsyncComponent(() => import('./Create.vue')),
      select: defineAsyncComponent(() => import('./SelectTenure.vue')),
      'investment-details': defineAsyncComponent(
        () => import('./InvestmentDetails.vue'),
      ),
      'payment-method': defineAsyncComponent(() => import('./Payment.vue')),
    };

    const step = computed(() => components[data.tab]);

    const navigateToSelectTenure = (investmentName: string): void => {
      data.investment.name = investmentName;
      changeTab('select');
    };

    const navigateToInvestmentDetails = ({
      investment_amount,
      investment_tenure,
    }: any): void => {
      data.investment.amount = investment_amount;
      data.investment.tenure_id = investment_tenure;
      changeTab('investment-details');
    };

    const create = async ({
      reference,
      provider,
    }: {
      reference: string;
      provider: string;
    }): Promise<void> => {
      data.loading = true;
      await createInvestment({ ...data.investment, reference, provider })
        .then((response: AxiosResponse) => {
          data.investmentResource = response?.data;
          modal.value.close();
          successModal.value.open();
        })
        .catch((error: AxiosError) => {
          data.error = error?.response?.data?.message;
          errorModal.value.open();
        })
        .finally(() => (data.loading = false));
    };

    const changeTab = (tab: string): string => (data.tab = tab);

    const open = (): void => modal.value.open();

    return {
      step,
      navigateToSelectTenure,
      navigateToInvestmentDetails,
      changeTab,
      modal,
      open,
      create,
      successModal,
      ...toRefs(data),
      errorModal,
    };
  },
});
